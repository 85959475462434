<template>
  <q-card v-if="app" class="q-ma-md">
    <q-card-section class="border-bottom q-px-none q-py-xs row items-center">
      <q-legend :label="app.name" :image="app.image || ''"/>
    </q-card-section>

    <q-card-section class="row q-pa-none" style="min-height: 80vh;">
      <div class="col-12 border-right q-py-sm" style="height: calc(100vh - 80px); overflow: auto;">
        <div v-if="model">
          <div v-if="!model.id && newFormComponents[app.adapter]" class="q-px-sm">
            <component
                :is="newFormComponents[app.adapter]"
                @change="handleChange"
                no-connect-btn
            />
          </div>

          <div v-else-if="model.id && defaultFormComponents[app.adapter]" class="q-px-sm">
            <component
                :is="defaultFormComponents[app.adapter]"
                @change="handleChange"
                no-connect-btn
            />
          </div>

          <div v-else-if="isDS || !Array.isArray(app.settings)" class="q-px-sm">
            <integration-form
                :integration="app"
                :model="model"
                :is-d-s="isDS"
                @change="handleChange"
            />
          </div>

          <q-tabs
              v-else
              v-model="tab"
              dense
              active-color="primary"
              indicator-color="primary"
              align="justify"
              class="text-grey fit"
              narrow-indicator
          >
            <q-tab
                v-for="group in app.settings"
                :key="group.name"
                :name="group.name"
                :label="$t(group.label || 'No name')"
            />
          </q-tabs>

          <q-separator/>

          <div
              v-if="!tab"
              class="row items-center justify-center text-subtitle1"
              style="min-height: 300px;"
          >
            {{ $t('Please select tab!') }}
          </div>

          <q-tab-panels v-model="tab" animated keep-alive>
            <q-tab-panel
                v-for="group in app.settings"
                :key="group.name"
                :name="group.name || ''"
            >
              <integration-tab
                  :model="model"
                  :tab="group"
                  @change="handleChange"
              />
            </q-tab-panel>
          </q-tab-panels>

          <sticky-bottom-header
              :is-loading="isSaveLoading"
              :is-active="hasChange"
              :save-label="$t(model.id ? 'Save' : 'Install')"
              @back="handleDiscard"
              @save="save"
          />
        </div>

        <div v-else class="row items-center justify-center fit">
          <div class="col-12 text-center">
            <div class="text-subtitle1">
              {{ $t('Please select connection or create new.') }}
            </div>

            <q-btn
                color="light-blue-9"
                icon="add"
                class="q-mt-sm"
                @click="prompt = true"
            />
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-dialog v-model="prompt" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">{{ $t('Connection') }}</div>
      </q-card-section>

      <form-builder ref="formBuilder" :schema="schema"/>
      <!--      <q-card-section class="q-pt-none">-->
      <!--        <q-input dense v-model="connectionName" autofocus @keyup.enter="prompt = false" />-->
      <!--      </q-card-section>-->

      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('Discard')" v-close-popup/>
        <q-btn flat :label="$t('Create')" @click="handleAdd" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import IntegrationForm from '../../components/integration-wizard/IntegrationForm.vue'
import ShopifyForm from './../../components/integration-wizard/ShopifyForm.vue'
import IntegrationTab from '../../components/integration-wizard/IntegrationTab.vue'
import Search from '../../components/search/Search'

// Utils
import _ from 'lodash'
import { buildQuery } from '../../utils/query-utils'
import { convertSourceIntegration, getRouteOptions } from '../../helpers/helpers'
import { convertEmbedded, difference } from '../../helpers/request-helpers'

export default {
  name: 'AppDefault',
  components: {
    IntegrationForm,
    ShopifyForm,
    IntegrationTab,
    Search,
  },
  data () {
    return {
      tab: '',
      newFormComponents: {
        'Orderadmin\\Integrations\\Shopify\\Service\\Integration': 'shopify-form'
      },
      defaultFormComponents: {},
      cleanModel: null,
      connectionName: null,
      connectionOwner: null,
      prompt: false,
      model: null,
      items: [],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isLoading: false,
      filter: '',
      filters: [
        { type: 'in', field: 'state', values: ['active', 'inactive'] }
      ],
      deliveryService: null,
      isSaveLoading: false,
      hasChange: false,
      deliveryId: null,
    }
  },
  computed: {
    ...mapGetters([
      'app',
      'appOptions',
      'appAdapters',
      'isSupervisior',
      'isClient',
      'isEmployee',
      'isAdministrator',
      'source',
    ]),
    isDS () {
      return this.app && this.app.adapter.includes('DeliveryServices')
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.connectionOwner,
                required: true,
                hint: this.$t('Select the user for the connection.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 8
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  this.connectionOwner = owner
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.connectionName,
                label: this.$t('Connection name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onChange: name => {
                  this.connectionName = name
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (!this.app) {
      this.loadApp(this.$route.params.id)
          .then(() => {
            this.onRequest({ pagination: { page: 1, per_page: 25 } })
          })
    }

    if (this.source) {
      this.setItem(this.source)
    } else if (this.$route.params.sourceId && this.$route.params.sourceId > 0) {
      this.loadSource(this.$route.params.sourceId).then(() => {
        this.setItem(this.source)
      })
    }
  },
  unmounted () {
    this.setApp(null)
  },
  methods: {
    ...mapActions([
      'loadApp',
      'loadSource',
    ]),
    ...mapMutations([
      'setApp',
      'addWarningNotification'
    ]),
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    handleChange (update) {
      this.model = update
      this.hasChange = true
    },
    handleAdd () {
      let item = {
        name: this.connectionName,
        handler: this.app.adapter,
        settings: {},
        _embedded: {}
      }

      if (this.connectionOwner && (this.isSupervisior || this.isAdministrator)) {
        item.owner = this.connectionOwner.id
      }

      this.setItem(item)
    },
    onRowClick (item) {
      this.setItem(item)
    },
    handleNameChange (result, item) {
      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ name: result }, item.id)
                : this.$service.iSource.save({ name: result }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.$service.deliveryServiceIntegration.save({ state }, item.id)
                : this.$service.iSource.save({ state }, item.id)
          })
          .then(item => {
            this.replaceItem(item)
          })
    },
    getSIntegrations (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    loadDeliveryService () {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: this.app.extId }
        ]
      }

      return this.$service.deliveryService.getAll(query)
          .then(({ items }) => {
            this.deliveryService = items[0]
            return this.deliveryService
          })
    },
    loadDSItems (query) {
      return Promise.resolve(!!this.deliveryService)
          .then(hasDS => {
            return hasDS
                ? this.deliveryService
                : this.loadDeliveryService()
          })
          .then(deliveryService => {
            this.deliveryId = deliveryService.id
            query.filter.push({ type: 'eq', field: 'deliveryService', value: deliveryService.id })

            return this.$service.deliveryServiceIntegration.getAll(query)
          })
    },
    loadSItems (query) {
      query.filter.push({ type: 'eq', field: 'handler', value: this.app.extId })

      return this.$service.iSource.getAll(query)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = [...this.filters]
      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]
      query.sort = { id: 'desc' }

      this.isLoading = true
      return Promise.resolve()
          .then(() => {
            return this.isDS
                ? this.loadDSItems(query)
                : this.loadSItems(query)
          })
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    setItem (item) {
      this.model = _.cloneDeep(item)
      this.cleanModel = item && item.id
          ? _.cloneDeep(item)
          : null
      this.hasChange = false
      this.tab = ''
    },
    replaceItem (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id == item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (!isFounded) {
        this.items = [item, ...this.items]
      }

      return this.items
    },
    save () {
      this.isSaveLoading = true

      return Promise.resolve(this.isDS)
          .then(isDS => {
            return isDS
                ? this.saveDSIntegration()
                : this.saveSIntegration()
          })
          .then(data => {
            this.setItem(data)
            this.replaceItem(data)
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    saveDSIntegration () {
      const ignoredFields = ['deliveryService', 'sender']

      let data = this.model.id
          ? convertEmbedded(difference(this.model, this.cleanModel), ignoredFields)
          : convertEmbedded(this.model)

      if (Object.keys(data).length === 0) {
        this.addWarningNotification('No changes!')
        return Promise.resolve(this.model)
      }

      if (data.settings) {
        data = convertSourceIntegration(data)
      }

      if (data.handler) {
        delete data.handler
      }
      if (data.owner) {
        delete data.owner
      }

      data.deliveryService = this.deliveryId
      data.mode = 'online'
      data.type = 'private'

      return this.$service.deliveryServiceIntegration.save(data, this.model.id)
    },
    saveSIntegration () {
      if (this.model.id) {
        let data = convertEmbedded(difference(this.model, this.cleanModel))

        if (data.settings) {
          data = convertSourceIntegration(data)
        }

        if (Object.keys(data).length === 0) {
          this.addWarningNotification('No changes!')
          return Promise.resolve(this.model)
        }

        return this.$service.iSource.save(data, this.model.id)
      }

      let data = { ...this.model }
      data = convertSourceIntegration(data)
      return this.$service.iSource.install(this.app.id, data)
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
  }
}
</script>
